<template>
    <template class='float-end'
              :class='determineBadgeClass()'>
        <font-awesome-icon size='xs' :icon='icon' v-if='icon' />

        <template class='mx-2' v-if='field === "tasks"'>
            <span class='mx-2'>
            {{ item.tasks?.length ?? '0' }}
            </span>
            <template v-if='item.tasks?.length  === null'>{{ plural_name }}</template>
            <template v-else-if='item.tasks?.length  > 1'>{{ plural_name }}</template>
            <template v-else-if='item.tasks?.length  > 1'>{{ plural_name }}</template>
            <template v-else>{{ name }}</template>
        </template>
        <template v-else>
            <template v-if='compare_field'>
                <span class='mx-2' v-if='item[field] > 0'>
                {{ item[field] ?? '0' }} / {{ item[compare_field] ?? '0' }}
                </span>
                <span v-else>
                    Add 
                </span>
            </template>
            <template v-else>
                <span class='mx-2' v-if='item[field] > 0'>
                    {{ item[field] }}
                </span>
                <span v-else>
                    Add 
                </span>
            </template>

            <template v-if='item[field] === null'>{{ plural_name }}</template>
            <template v-else-if='item[field] > 1'>{{ plural_name }}</template>
            <template v-else-if='item[field] > 1'>{{ plural_name }}</template>
            <template v-else>{{ name }}</template>
        </template>
    </template>
</template>

<script setup>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { defineProps } from 'vue';

    const props = defineProps({
        item: Object,
        icon: String,
        name: String,
        plural_name: String,
        field: String,
        compare_field: String
    });

    const determineBadgeClass = () => {
        if (props.compare_field) {

            // CHeck if the field is greater than the compare field if it is return danger
            if (props.item[props.field] > props.item[props.compare_field]) {
                return 'badge badge-danger';
            } else if ((props.item[props.field] !== props.item[props.compare_field]) || (props.item[props.field] === null && props.item[props.compare_field] === null)) {
                return 'badge outline-badge-primary';
            } else {
                return 'badge badge-primary';
            }

        } else {

            if (props.field === 'tasks') {
                if (props.item.tasks?.length > 0) {
                    return 'badge badge-primary';
                } else {
                    return 'badge outline-badge-primary';
                }
            }

            if (props.item[props.field] > 0) {
                return 'badge badge-primary';
            } else {
                return 'badge outline-badge-primary';
            }
        }
    };

</script>
