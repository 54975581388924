<template>
    <div class='row p-0 m-0'>
        <div class='col-10'>
            <p class='m-0 p-0 p-header'>{{ item.name }}</p>
            <p class='item-title mb-0'>
                <font-awesome-icon icon='heart' size='xs' v-if='item.is_favorite'  class='my_icon text-primary'/>
                <font-awesome-icon icon='archive' size='xs' v-if='item.is_archive' class='mx-2' />
                <font-awesome-icon icon='file' size='xs' v-if='item.is_template' class='mx-2' />
                {{ DateFormat(item.created_at) }}

                <small class='fs-6 float-end' data-bs-toggle='tooltip' :title='item.category'>
                    <font-awesome-icon icon='circle' size='xs' class='my_icon'
                                       :color="'#' + color" />
                </small>
            </p>
        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/kb/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import moment from 'moment';

    const props = defineProps({
        item: Object,
        color: String,
        is_archive: Boolean
    });

    const DateFormat = (value) => {
        return moment(value).format('YYYY-MM-DD');
    };

</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .item{
        min-height: 60px;
        padding-top: 25px;
    }
</style>
