import UserService from '@/services/user.service';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import section_info from '@/json/sections.json';
import section_lookup_info from '@/json/lookups.json';


export function get_lookup_fields() {
    return ['os_architecture_id', 'privacy_type_id', 'snmp_version_id', 'auth_protocol_id', 'room_id', 'source_id', 'app_id', 'software_id',
        'phone_provider_id', 'fax_phone_provider_id', 'type_id', 'status_id', 'category_id', 'location_id', 'access_control_type_id', 'contact_id',
        'vendor_id', 'source_type_id', 'residual_risk_id', 'inherent_risk_id', 'email_spam_provider_id', 'frequency_id', 'device_id', 'provider_id',
        'hardening_template_id', 'impact_level_id', 'employee_id', 'priority_id', 'client_id', 'vendor_id', 'security_zone_classification_id',
        'status_id', 'type_id', 'type_id2', 'status_id2', 'coauthor_user_id', 'author_user_id',
        'data_classification_id', 'asset_classification_id', 'assigned_user_id', 'reported_user_id', 'folder_id', 'dns_filter_provider_id'];
}

export function getSectionInfo(section, isLookup = false) {
    const columns = ref(section !== 'my_tasks' && section !== 'logs' && section !== 'backups' && section !== 'expirations' ? ['select'] : []);
    const fields = ref([]);
    const available_columns = ref({});
    const required_fields = ref({});
    const column_display = ref({});
    const default_fields = ref({});
    const allLookups = ref({});
    const jsonFile = isLookup ? section_lookup_info : section_info;

    if (section.includes('/')) {
        section = section.split('/').slice(2).join('/');
    }

    const fieldsObjectJson = jsonFile[section].fields;
    Object.entries(fieldsObjectJson).forEach(([key, field]) => {

        if (field.isRequired) {
            required_fields.value[key] = true;
        }

        if (field.label !== 'Archive') {
            available_columns.value[key] = field.isRequired;
        }
        if (key !== 'name') {
            column_display.value[key] = 'min_tablet';
        }

        if (field.defaultValue) {
            default_fields.value[key] = field.defaultValue;
        }

        if (field.showColumn) {
            columns.value.push(key);
        }

        fields.value.push(field.label);
    });

    if (section !== 'logs' && section !== 'my_tasks' && section !== 'expirations' && section !=='backups') {
        columns.value.push('actions');
    }


    const lookups = jsonFile[section].lookups;
    allLookups.value = Object.fromEntries(
        Object.values(lookups).flatMap(lookupCategory =>
            Object.entries(lookupCategory)
        )
    );
    
    return {
        'columns': columns.value,
        'raw_fields': fieldsObjectJson,
        'fields': fields.value,
        'filters': jsonFile[section].filters,
        'available_columns': available_columns.value,
        'required_fields': required_fields.value,
        'column_display': column_display.value,
        'default_fields': default_fields.value,
        'allLookups': allLookups.value,
        'importOptions': jsonFile[section].importOptions,
        'lookups': jsonFile[section].lookups.default,
        'metadata': jsonFile[section].metadata,
        'lookup_icons': jsonFile[section].lookups.icons,
        'lookup_images': jsonFile[section].lookups.images,
        'lookup_colors': jsonFile[section].lookups.colors,
        'breadcrumbs': jsonFile[section].metadata.breadcrumbs
    };
}


export const getLookups = async (section, lookups, sub_section = null, my_store = null) => {
    let store = useStore();

    if (!store) {

        if (my_store) {
            store = my_store;
        } else {
            throw new Error('Store is not available.');
        }
    }

    const computedLookups = computed(() => store.state.lookups);

    const missing_lookups = Object.keys(lookups).filter(key => {
        for (const lookupType of ['colors', 'icons', 'images']) {
            if ((computedLookups[lookupType] && computedLookups[lookupType][key]) || computedLookups[key]) {
                return false; // Lookup found, not missing
            }
        }
        return true; // Lookup missing
    });

    if (missing_lookups.length > 0) {
        let mySection = sub_section ? sub_section : section;
        try {
            const response = await UserService.getData(`/${mySection}/id_lookups/`);
            store.commit('setLookups', { key: null, value: response.data });
        } catch (error) {
            throw new Error(`Failed to fetch lookups for ${mySection}: ${error.message}`);
        }
    }

    return store.state.lookups; // Return updated lookups from the store
};
