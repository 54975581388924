<template>
    <th slot-scope='props' v-on='props.thEvents' v-bind='props.thAttrs' :aria-label='props.heading === "" ? "Empty Select": props.heading '>
       <span class='custom-input' :title='props.title' v-if="props.heading === 'Select'">
            <label for="select_all" class="visually-hidden">Select all items</label>
            <input type='checkbox' id='select_all' aria-label="Select all items" />
        </span>
        <span class='VueTables__heading' :title='props.title' v-else-if='props.heading.length > 0'>
            <span :class='{"clickable":props.heading !== "Actions" }' @click='updateSortBy(props.heading)'>
                <font-awesome-icon icon='lock' v-if='props.heading === "Is encrypted"' />
                <font-awesome-icon icon='heart' v-if='props.heading === "Is favorite"' class='text-primary' />
                <font-awesome-icon icon='archive' v-if='props.heading === "Is archive"' />
                <font-awesome-icon icon='shield-virus' v-if='props.heading === "Has Endpoint Protection"' />
                <font-awesome-icon icon='cloud-upload-alt' v-if='props.heading === "Has backup"' />
                <font-awesome-icon icon='user-secret' v-if='props.heading === "Has agent"' />
                <font-awesome-icon icon='shield' v-if='props.heading === "Is private"' />
                
                {{ toTitleCase(props.heading) }}
                
                <template v-if='props.heading !== "Actions"'>
                <font-awesome-icon icon='spinner' size='1x' spin v-if='loading' class='float-end' />
                    <template v-else>
                        <font-awesome-icon icon='sort-up'
                                           v-if='toTitleCase(currentSortByTitle) === toTitleCase(props.heading) && currentSortDirection === "ascending"'
                                           class='float-end' />
                        <font-awesome-icon icon='sort-down'
                                           v-else-if='toTitleCase(currentSortByTitle) === toTitleCase(props.heading) && currentSortDirection === "descending"'
                                           class='float-end' />
                        <font-awesome-icon icon='sort' v-else
                                           color='gray'
                                           class='float-end' />
                    </template>
                </template>

            </span>
        </span>
        <vt-sort-control />
    </th>
</template>

<script>
    import VtSortControl from 'v-tables-3/compiled/components/VtSortControl';
    import { h } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { useStore } from 'vuex';

    import { mapState } from 'vuex';
    import { columnTitleCase, words_to_replace } from '@/composables/utils';
    import { get_lookup_fields } from '@/composables/load-lookups';

    export default {
        name: 'VtTableHeading',
        components: {
            FontAwesomeIcon,
            VtSortControl,
            vnodes: {
                functional: true,
                render: (ctx) => typeof ctx.$attrs.vnodes === 'object' ? h(ctx.$attrs.vnodes) : [h(ctx.$attrs.vnodes)]
            }
        },
        computed: {
            ...mapState(['currentSortBy', 'currentSortDirection', 'currentSortByTitle'])
        },
        data() {
            return {
                loading: false
            };
        },
        props: ['props'],
        setup() {
            const store = useStore();
            return { store };
        },
        methods: {
            toTitleCase(str) {
                return columnTitleCase(str);
            },
            updateSortBy(heading) {

                this.store.commit('setSortByTitle', heading);
                this.loading = true;
                // flip the words to replace
                let words_to_replace_flipped = {};
                for (const [key, value] of Object.entries(words_to_replace)) {
                    words_to_replace_flipped[value] = key;
                }

                // get the original heading in lower case 
                let original_heading = heading.toLowerCase();
                for (const [key, value] of Object.entries(words_to_replace_flipped)) {
                    let regex = new RegExp(key, 'g');
                    original_heading = original_heading.replace(regex, value);
                }

                // replace space with _
                original_heading = original_heading.replace(/ /g, '_');

                const lookup_fields = get_lookup_fields();

                // check if original_heading is in lookup_fields + "_id"
                if (lookup_fields.includes(original_heading + '_id')) {
                    original_heading = original_heading + '_id';
                }

                // Update the direction and the column to sort by
                if (this.store.state.currentSortBy === original_heading) {
                    const sortDirectionChanged = this.store.state.currentSortDirection === 'ascending' ? 'descending' : 'ascending';

                    if (this.store.state.currentSortDirection !== sortDirectionChanged) {
                        this.store.commit('setSortDirection', sortDirectionChanged);
                    } else {
                        this.store.commit('setSortDirection', 'ascending');
                    }

                }

                if (this.store.state.currentSortBy !== original_heading) {
                    this.store.commit('setSortBy', original_heading);
                }

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        }
    };
</script>
