import UserService from '@/services/user.service';
import moment from 'moment';
import TokenService from '@/services/token.service';
import useClipboard from 'vue-clipboard3';
import store from '../store';
import { nextTick, ref, toRaw } from 'vue';

export const updateViewSettings = async (section, field, value, update = true) => {

    if (!section || !field || section.length === 0 || field.length === 0) {
        return;
    }

    const token = TokenService.getToken();
    let view_settings = token.user.view_settings;
    if (!view_settings) {
        view_settings = {};
    }

    if (!view_settings[section]) {
        view_settings[section] = {};
    }

    view_settings[section][field] = value;

    // Update View Settings
    token.user.view_settings = view_settings;
    TokenService.setToken(token);
    await store.dispatch('auth/updateToken', token);

    // Update User View Settings via API
    if (update) {
        await UserService.updateData(`/users/viewSettings`, { view_settings: view_settings });
    }
};

export const cleanForm = (form, og_id) => {

    // deep copy form
    let my_form = JSON.parse(JSON.stringify(form));

    Object.keys(my_form).forEach((key) => {
        if (key !== 'tags' && key !== 'machine_functions' && key !== 'ports' && key !== 'ports2' && key !== 'groups' && key !== 'report' && key !== 'security_groups' && key !== 'skills' && key !== 'experiences') {
            if (my_form[key] && typeof my_form[key] === 'object' && my_form[key].value) {
                my_form[key] = my_form[key].value;
            } else if (my_form[key] && typeof my_form[key][0] !== 'undefined' && my_form[key][0].value) {
                my_form[key] = my_form[key][0].value;
            } else {
                if (key === 'profile_url' && my_form[key] && my_form[key].length > 0) {
                    if (my_form[key].includes('icon')) {
                        my_form[key] = my_form[key].icon;
                    }
                } else {
                    if (my_form[key] && typeof my_form[key] === 'object') {
                        my_form[key] = my_form[key].icon;
                    }
                }
            }

            if (my_form[key] && Array.isArray(my_form[key]) && my_form[key].length === 0) {
                my_form[key] = null;
            }
        }
    });
    if (!og_id) {
        my_form.id = null;
    }

    return my_form;
};

export const showMessage = (msg = '', type = 'success') => {
    let timer = 3000;
    if (type === 'error') {
        timer = 10000;
    }
    const toast = window.Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: timer
    });
    toast.fire({
        icon: type,
        title: msg,
        padding: '10px 20px'
    });
};

export const convertBytes = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Bytes';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getFile = async (file_type, id) => {
    let configuration = {};
    await UserService.getData(`/${file_type}/?filters={"id":{"enabled":true,"value":[${id}]}}`).then((r) => {

        if (r.data.items[0]) {
            configuration = r.data.items[0];
        }
    });
    return configuration;
};

const checkStoreLookupValues = async (field, values) => {

    const lookup_values = toRaw(await store.getters.getLookupValues());
    if (lookup_values[field]) {
        for (const value of values) {
            if (!lookup_values[field].find((stored_value) => stored_value.id === value.id)) {
                store.commit('appendLookup', { 'key': field, 'value': value });
            }
        }

        return values;
    }

    // try colors section 
    if (lookup_values.colors && lookup_values.colors[field]) {
        for (const value of values) {
            if (!lookup_values.colors[field].find((stored_value) => stored_value.id === value.id)) {
                store.commit('appendLookup', { 'key': field, 'value': value, 'section': 'colors' });
            }
        }

        return values;
    }

    // try icons section
    if (lookup_values.icons && lookup_values.icons[field]) {
        for (const value of values) {
            if (!lookup_values.icons[field].find((stored_value) => stored_value.id === value.id)) {
                store.commit('appendLookup', { 'key': field, 'value': value, 'section': 'icons' });
            }
        }

        return values;
    }

    // try images section
    if (lookup_values.images && lookup_values.images[field]) {
        for (const value of values) {
            if (!lookup_values.images[field].find((stored_value) => stored_value.id === value.id)) {
                store.commit('appendLookup', { 'key': field, 'value': value, 'section': 'images' });
            }
        }

        return values;
    }

    return values;
};

const fetchData = async (url, query, item_id = null) => {
    try {

        if (item_id) {
            url = url.replace('{item_id}', item_id);
        }

        if (query === 'undefined' || query === undefined) {
            query = '';
        }

        let response;
        if (url.includes('/list')) {
            response = await UserService.getData(`${url}?size=50&filters={"is_archive":{"enabled":true,"option":"none","value":false}, "is_template":{"enabled":true,"option":"none","value":true}}&search_query=${query}&sort=name_1`);
        } else {
            response = await UserService.getData(`${url}?size=50&filters={"is_archive":{"enabled":true,"option":"none","value":false}}&search_query=${query}&sort=name_1`);
        }

        const results = response.data.items;

        const temp_field = url.replace(/\//g, '');
        const singular_fields = ['ip_addresses', 'issue_management', 'software', 'organizations'];
        const field = singular_fields.includes(temp_field) ? temp_field : temp_field.slice(0, -1);
        const full_field = field + '_id';

        if (url === '/employees/' || url === '/contacts/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                profile_url: x.profile_url && x.profile_url.length > 0 ? x.profile_url : 'empty.png',
                name: x.first_name && x.first_name.trim().length > 0 && x.last_name && x.last_name.trim().length > 0
                    ? `${x.first_name} ${x.last_name}`
                    : x.first_name && x.first_name.trim().length > 0
                        ? x.first_name
                        : x.last_name && x.last_name.trim().length > 0
                            ? x.last_name
                            : x.display_name && x.display_name.trim().length > 0
                                ? x.display_name
                                : x.email && x.email.trim().length > 0
                                    ? x.email
                                    : 'No Name Provided',
                thumbnail: x.thumbnail,
                email: x.email,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/applications/' || url === '/organizations/lookups/storage_types/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: `${x.name}`,
                image: x.image,
                thumbnail: x.thumbnail,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url.includes('/subnets/')) {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: x.name + (x.cidr ? ' - ' + x.cidr : ''),
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/devices/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: x.name + (x.model ? ' - ' + x.model : '') + (x.brand ? ' - ' + x.brand : '') + (x.serial ? ' (' + x.serial + ')' : ''),
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/organizations/lookups/seat_types' || url === '/organizations/lookups/device_categories/' || url === '/organizations/lookups/incidentincident_response_categories/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: `${x.name}`,
                icon: x.icon,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/organizations/lookups/device_statuses/' || url === '/organizations/lookups/expense_statuses/' || url === '/organizations/lookups/ip_address_statuses/' || url === '/organizations/lookups/network_statuses/' || url === '/organizations/lookups/procedure_statuses/' || url === '/organizations/lookups/project_statuses/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: `${x.name}`,
                color: x.color,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/organizations/lookups/project_statuses/' || url === '/organizations/lookups/document_categories/' || url === '/organizations/lookups/environments/' || url === '/organizations/lookups/configuration_categories/' || url === '/organizations/lookups/hardening_template_categories/' || url === '/organizations/lookups/knowledge_base_categories/' || url === '/organizations/lookups/faq_categories/' || url === '/organizations/lookups/configuration_categories/' || url === '/organizations/lookups/expense_categories/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: `${x.name}`,
                color: x.color,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/organizations/lookups/asset_classifications' || url === '/organizations/lookups/data_classifications' || url === '/organizations/lookups/inherent_risks' || url === '/organizations/lookups/residual_risks' || url === '/organizations/lookups/security_zone_classifications') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                name: `${x.name}`,
                color: x.color,
                value: x.id,
                id: x.id
            }))) : [];
        } else if (url === '/users/' || url === '/users/eligible_owners/') {
            return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
                profile_url: x.profile_url && x.profile_url.length > 0 ? x.profile_url : 'profile-13.svg',
                name: x.first_name && x.first_name.trim().length > 0 && x.last_name && x.last_name.trim().length > 0
                    ? `${x.first_name} ${x.last_name}`
                    : x.first_name && x.first_name.trim().length > 0
                        ? x.first_name
                        : x.last_name && x.last_name.trim().length > 0
                            ? x.last_name
                            : x.display_name && x.display_name.trim().length > 0
                                ? x.display_name
                                : x.email && x.email.trim().length > 0
                                    ? x.email
                                    : 'No Name Provided',
                email: x.email,
                value: x.id,
                id: x.id
            }))) : [];
        }

        return results && results.length > 0 ? checkStoreLookupValues(full_field, results.map(x => ({
            name: x.name, value: x.id,
            id: x.id
        }))) : [];
    } catch (error) {
        return [];
    }
};

export const asyncFindAll = async (url, query) => fetchData(url, query);
export const asyncFindAllTags = async (query) => fetchData('/organizations/lookups/tags/', query);
export const asyncFindAllEmployees = async (query) => fetchData('/employees/', query);
export const asyncFindAllFiles = async (file_type, query) => fetchData(`/${file_type}/list`, query);
export const asyncFindAllFolders = async (query) => fetchData('/organizations/lookups/folders/', query);
export const asyncFindAllPorts = async (query) => fetchData('/organizations/lookups/ports/', query);
export const asyncFindAllLocations = async (query) => fetchData('/organizations/lookups/locations/', query);
export const asyncFindAllApplications = async (query) => fetchData('/applications/', query);
export const asyncFindAllStorageTypes = async (query) => fetchData('/organizations/lookups/storage_types/', query);
export const asyncFindAllDevices = async (query) => fetchData('/devices/', query);
export const asyncFindAllDeviceCategories = async (query) => fetchData('/organizations/lookups/device_categories/', query);
export const asyncFindAllDeviceBrands = async (query) => fetchData('/organizations/lookups/device_brands/', query);
export const asyncFindAllDeviceModels = async (query) => fetchData('/organizations/lookups/device_models/', query);
export const asyncFindAllDeviceStatuses = async (query) => fetchData('/organizations/lookups/device_statuses/', query);
export const asyncFindAllDeviceTypes = async (query) => fetchData('/organizations/lookups/device_types/', query);
export const asyncFindAllDeviceConditions = async (query) => fetchData('/organizations/device_conditions', query);
export const asyncFindAllOSs = async (query) => fetchData('/organizations/oss', query);
export const asyncFindAllProcessorFamilies = async (query) => fetchData('/organizations/lookups/processor_families/', query);
export const asyncFindAllConnectionTypes = async (query) => fetchData('/organizations/lookups/network_connection_types/', query);
export const asyncFindAllNetworkStatuses = async (query) => fetchData('/organizations/lookups/network_statuses/', query);
export const asyncFindAllMachineFunctions = async (query) => fetchData('/organizations/lookups/machine_functions/', query);
export const asyncFindAllSecurityGroups = async (query) => fetchData('/organizations/lookups/security_groups/', query);
export const asyncFindAllAvailabilityZones = async (query) => fetchData('/organizations/lookups/virtual_machine_availability_zones/', query);
export const asyncFindAllVirtualMachineStatuses = async (query) => fetchData('/organizations/lookups/virtual_machine_statuses/', query);
export const asyncFindAllVirtualMachineTypes = async (query) => fetchData('/organizations/lookups/virtual_machine_types/', query);
export const asyncFindAllPlatforms = async (query) => fetchData('/organizations/lookups/platforms/', query);
export const asyncFindAllVendors = async (query) => fetchData('/vendors/', query);
export const asyncFindAllClients = async (query) => fetchData('/clients/', query);
export const asyncFindAllExpenseCategories = async (query) => fetchData('/organizations/lookups/expense_categories/', query);
export const asyncFindAllExpenseStatuses = async (query) => fetchData('/organizations/lookups/expense_statuses/', query);
export const asyncFindAllExpenseTypes = async (query) => fetchData('/organizations/lookups/expense_types/', query);
export const asyncFindAllFrequencies = async (query) => fetchData('/organizations/frequencies', query);
export const asyncFindAllLicenseTypes = async (query) => fetchData('/organizations/lookups/license_types/', query);
export const asyncFindAllLicenseSectors = async (query) => fetchData('/organizations/lookups/license_sectors/', query);
export const asyncFindAllLicenseRoles = async (query) => fetchData('/organizations/lookups/license_roles/', query);
export const asyncFindAllSubscriptionTypes = async (query) => fetchData('/organizations/lookups/subscription_types/', query);
export const asyncFindAllSubscriptionSectors = async (query) => fetchData('/organizations/lookups/subscription_sectors/', query);
export const asyncFindAllProjectStatuses = async (query) => fetchData('/organizations/lookups/project_statuses/', query);
export const asyncFindAllProcedureStatuses = async (query) => fetchData('/organizations/lookups/procedure_statuses/', query);
export const asyncFindAllEndpointProtectionPlatforms = async (query) => fetchData('/organizations/lookups/endpoint_protection_platforms/', query);
export const asyncFindAllIncidentResponseCategories = async (query) => fetchData('/organizations/lookups/incident_response_categories/', query);
export const asyncFindAllRooms = async (query, item_id) => fetchData('/locations/{item_id}/rooms/', query, item_id);
const fetchItems = async (url, id) => {
    try {
        const response = await UserService.getData(`${url}/?filters={"id":{"enabled":true,"value":[${id}]}}`);

        if (response.status >= 200 && response.status < 300) {
            if (response.data?.items && response.data.items.length > 0) {
                const item = response.data.items[0];

                if (url === '/devices/') {
                    return buildDeviceResponse(id, item);
                }

                if (url === '/employees/') {
                    return buildEmployeeResponse(id, item);
                }
            } else {
                showMessage('No items found', 'warning');
            }
        } else {
            showMessage('Error fetching data: Invalid response status', 'error');
        }
    } catch (error) {
        showMessage('Error fetching data', 'error');
        return null;
    }
};

const buildDeviceResponse = (id, item) => ({
    'value': id,
    'name': [
        item.name,
        item.model && ` - ${item.model}`,
        item.brand && ` - ${item.brand}`,
        item.serial && `(${item.serial})`
    ].filter(Boolean).join(' ')
});

const buildEmployeeResponse = (id, item) => ({
    'value': id,
    'name': [
        `${item.first_name} ${item.last_name}`,
        item.email && `(${item.email})`
    ].filter(Boolean).join(' ')
});

export const asyncFindEmployee = async (id) => fetchItems('/employees/', id);
export const asyncFindDevice = async (query) => fetchItems('/devices/', query);


export const initTooltip = () => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
        const tooltipInstance = new window.bootstrap.Tooltip(tooltipTriggerEl);

        // listen to the shown.bs.tooltip event
        tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
            setTimeout(() => {
                tooltipInstance.hide();
            }, 3000);
        });
        return tooltipInstance;
    });
};


export const daysFromNow = (startDate) => {
    const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
    const TRIAL_PERIOD_DAYS = 14;

    const startDateObj = new Date(startDate);
    const trialEndDate = new Date(startDateObj.getTime() + (TRIAL_PERIOD_DAYS * MILLISECONDS_IN_A_DAY));
    let nextBillingCalculatedDate;
    let daysRemaining;

    nextBillingCalculatedDate = moment(trialEndDate);
    daysRemaining = moment(nextBillingCalculatedDate).diff(moment(), 'days');

    // Format the daysRemaining with commas
    return daysRemaining.toLocaleString();
};


const requires_color = ['device_status', 'network_status', 'project_status', 'virtual_machine_status', 'expense_status', 'expense_category', 'expense_category_', 'document_category', 'environment', 'configuration_category', 'faq_category', 'knowledge_base_category', 'hardening_template_category', 'ip_address_status', 'data_classification', 'asset_classification', 'security_zone_classification', 'issue_management_status', 'incident_response_status', 'procedure_status'];
const requires_icon_svg = ['device_category'];
const requires_image = ['application', 'storage_type'];
const requires_icon = ['license_type', 'license_sector', 'subscription_type', 'subscription_sector', 'incident_response_category'];

const TAG_TYPES = {

    firewall: {
        tagKey: 'firewall_id',
        lookupKey: 'firewall_id',
        endpoint: '/organizations/lookups/firewalls/'
    },
    network_status: {
        tagKey: 'status_id',
        lookupKey: 'network_status_id',
        endpoint: '/organizations/lookups/network_statuses/'
    },
    contact: {
        tagKey: 'contact_id',
        lookupKey: 'contact_id',
        endpoint: '/contacts/'
    },
    application: {
        tagKey: 'application_id',
        lookupKey: 'application_id',
        endpoint: '/applications/'
    },
    contact_type: {
        tagKey: 'type_id',
        lookupKey: 'contact_type_id',
        endpoint: '/organizations/lookups/contact_types/'
    },
    group: {
        tagKey: 'groups',
        lookupKey: 'groups',
        endpoint: '/organizations/lookups/groups/'
    },
    tag: {
        tagKey: 'tags',
        lookupKey: 'tags',
        endpoint: '/organizations/lookups/tags/'
    },
    open_port: {
        tagKey: 'ports',
        lookupKey: 'ports',
        endpoint: '/organizations/lookups/ports/'
    },
    open_port2: {
        tagKey: 'ports2',
        lookupKey: 'ports2',
        endpoint: '/organizations/lookups/ports/'
    },
    device_brand: {
        tagKey: 'brand_id',
        lookupKey: 'brand_id',
        endpoint: '/organizations/lookups/device_brands/'
    },
    device_model: {
        tagKey: 'model_id',
        lookupKey: 'model_id',
        endpoint: '/organizations/lookups/device_models/'
    },
    folder: {
        tagKey: 'folder_id',
        lookupKey: 'folder_id',
        endpoint: '/organizations/lookups/folders/'
    },
    location: {
        tagKey: 'location_id',
        lookupKey: 'location_id',
        endpoint: '/locations/'
    },
    vendor: {
        tagKey: 'vendor_id',
        lookupKey: 'vendor_id',
        endpoint: '/vendors/'
    },
    client: {
        tagKey: 'client_id',
        lookupKey: 'client_id',
        endpoint: '/clients/'
    },
    phone_provider: {
        tagKey: 'phone_provider_id',
        lookupKey: 'phone_provider_id',
        endpoint: '/organizations/lookups/phone_providers/'
    },
    fax_phone_provider: {
        tagKey: 'fax_phone_provider_id',
        lookupKey: 'fax_phone_provider_id',
        endpoint: '/organizations/lookups/phone_providers/'
    },
    issue_management_status: {
        tagKey: 'status_id',
        lookupKey: 'issue_management_status_id',
        endpoint: '/organizations/lookups/issue_management_statuses/'
    },
    incident_response_status: {
        tagKey: 'status_id',
        lookupKey: 'incident_response_status_id',
        endpoint: '/organizations/lookups/incident_response_statuses/'
    },
    procedure_status: {
        tagKey: 'status_id',
        lookupKey: 'procedure_status_id',
        endpoint: '/organizations/lookups/procedure_statuses/'
    },
    data_critical_level: {
        tagKey: 'data_critical_level_id',
        lookupKey: 'data_critical_level_id',
        endpoint: '/organizations/lookups/data_critical_levels/'
    },
    data_sensitivity_level: {
        tagKey: 'data_sensitivity_level_id',
        lookupKey: 'data_sensitivity_level_id',
        endpoint: '/organizations/lookups/data_sensitivity_levels/'
    },
    endpoint_protection_platform: {
        tagKey: 'endpoint_protection_platform_id',
        lookupKey: 'endpoint_protection_platform_id',
        endpoint: '/organizations/lookups/endpoint_protection_platforms/'
    },
    privilege: {
        tagKey: 'type_id',
        lookupKey: 'privilege_types_id',
        endpoint: '/organizations/lookups/privilege_types/'
    },
    vrf: {
        tagKey: 'vrf_id',
        lookupKey: 'vrf_id',
        endpoint: '/organizations/lookups/vrfs/'
    },
    encryption_type: {
        tagKey: 'encryption_type_id',
        lookupKey: 'encryption_type_id',
        endpoint: '/organizations/lookups/encryption_types/'
    },
    backup_type: {
        tagKey: 'backup_type_id',
        lookupKey: 'backup_type_id',
        endpoint: '/organizations/lookups/backup_types/'
    },
    rmm_agent: {
        tagKey: 'rmm_agent_id'
        , lookupKey: 'rmm_agent_id',
        endpoint: '/organizations/lookups/rmm_agents/'
    },
    ip_address_status: {
        tagKey: 'status_id',
        lookupKey: 'ip_address_status_id',
        endpoint: '/organizations/lookups/ip_address_statuses/'
    },
    email_spam_provider: {
        tagKey: 'email_spam_provider_id',
        lookupKey: 'email_spam_provider_id',
        endpoint: '/organizations/lookups/email_spam_providers/'
    },
    dns_filter_provider: {
        tagKey: 'dns_filter_provider_id',
        lookupKey: 'dns_filter_provider_id',
        endpoint: '/organizations/lookups/dns_filter_providers/'
    },
    mfa_provider: {
        tagKey: 'mfa_provider_id',
        lookupKey: 'mfa_provider_id',
        endpoint: '/organizations/lookups/mfa_providers/'
    },
    processor_family: {
        tagKey: 'processor_family_id',
        lookupKey: 'processor_family_id',
        endpoint: '/organizations/lookups/processor_families/'
    },
    device_type: {
        tagKey: 'type_id',
        lookupKey: 'device_type_id',
        endpoint: '/organizations/lookups/device_types/'
    },
    employee_type: {
        tagKey: 'type_id',
        lookupKey: 'employee_type_id',
        endpoint: '/organizations/lookups/employee_types/'
    },
    device_category: {
        tagKey: 'category_id',
        lookupKey: 'device_category_id',
        endpoint: '/organizations/lookups/device_categories/'
    },
    incident_response_category: {
        tagKey: 'incident_response_category_id',
        lookupKey: 'incident_response_category_id',
        endpoint: '/organizations/lookups/incident_response_categories/'
    },
    device_status: {
        tagKey: 'status_id',
        lookupKey: 'device_status_id',
        endpoint: '/organizations/lookups/device_statuses/'
    },
    project_status: {
        tagKey: 'status_id',
        lookupKey: 'project_status_id',
        endpoint: '/organizations/lookups/project_statuses/'
    },
    virtual_machine_status: {
        tagKey: 'status_id',
        lookupKey: 'virtual_machine_status_id',
        endpoint: '/organizations/lookups/virtual_machine_statuses/'
    },
    virtual_machine_type: {
        tagKey: 'type_id',
        lookupKey: 'virtual_machine_type_id',
        endpoint: '/organizations/lookups/virtual_machine_types/'
    },
    platform: {
        tagKey: 'platform_id',
        lookupKey: 'platform_id',
        endpoint: '/organizations/lookups/platforms/'
    },
    availability_zone: {
        tagKey: 'availability_zone_id',
        lookupKey: 'availability_zone_id',
        endpoint: '/organizations/lookups/virtual_machine_availability_zones/'
    },
    machine_function: {
        tagKey: 'machine_functions',
        lookupKey: 'machine_functions',
        endpoint: '/organizations/lookups/machine_functions/'
    },
    skill: {
        tagKey: 'skills',
        lookupKey: 'skills',
        endpoint: '/organizations/lookups/skills/'
    },
    experience: {
        tagKey: 'experiences',
        lookupKey: 'experiences',
        endpoint: '/organizations/lookups/experiences/'
    },
    security_group: {
        tagKey: 'security_groups',
        lookupKey: 'security_groups',
        endpoint: '/organizations/lookups/security_groups/'
    },
    network_connection_type: {
        tagKey: 'type_id',
        lookupKey: 'network_connection_type_id',
        endpoint: '/organizations/lookups/network_connection_types/'
    },
    network_connection_type2: {
        tagKey: 'type_id2',
        lookupKey: 'network_connection_type_id',
        endpoint: '/organizations/lookups/network_connection_types/'
    },
    storage_type: {
        tagKey: 'type_id',
        lookupKey: 'storage_type_id',
        endpoint: '/organizations/lookups/storage_types/'
    },
    provider: {
        tagKey: 'provider_id',
        lookupKey: 'provider_id',
        endpoint: '/organizations/lookups/training_providers/'
    },
    expense_type: {
        tagKey: 'expense_type_id',
        lookupKey: 'expense_type_id',
        endpoint: '/organizations/lookups/expense_types/'
    },
    expense_status: {
        tagKey: 'status_id',
        lookupKey: 'expense_status_id',
        endpoint: '/organizations/lookups/expense_statuses/'
    },
    expense_category: {
        tagKey: 'category_id',
        lookupKey: 'expense_category_id',
        endpoint: '/organizations/lookups/expense_categories/'
    },
    license_sector: {
        tagKey: 'sector_id',
        lookupKey: 'sector_id',
        endpoint: '/organizations/lookups/license_sectors/'
    },
    license_type: {
        tagKey: 'type_id',
        lookupKey: 'license_type_id',
        endpoint: '/organizations/lookups/license_types/'
    },
    license_role: {
        tagKey: 'license_role_id',
        lookupKey: 'license_role_id',
        endpoint: '/organizations/lookups/license_roles/'
    },
    subscription_sector: {
        tagKey: 'sector_id',
        lookupKey: 'sector_id',
        endpoint: '/organizations/lookups/subscription_sectors/'
    },
    subscription_type: {
        tagKey: 'type_id',
        lookupKey: 'subscription_type_id',
        endpoint: '/organizations/lookups/subscription_types/'
    },
    subscription_role: {
        tagKey: 'subscription_role_id',
        lookupKey: 'subscription_role_id',
        endpoint: '/organizations/lookups/subscription_roles/'
    },
    service_role: {
        tagKey: 'service_role_id',
        lookupKey: 'service_role_id',
        endpoint: '/organizations/lookups/service_roles/'
    },
    document_category: {
        tagKey: 'category_id',
        lookupKey: 'document_category_id',
        endpoint: '/organizations/lookups/document_categories/'
    },
    configuration_category: {
        tagKey: 'configuration_category_id',
        lookupKey: 'configuration_category_id',
        endpoint: '/organizations/lookups/configuration_categories/'
    },
    hardening_template_category: {
        tagKey: 'hardening_template_category_id',
        lookupKey: 'hardening_template_category_id',
        endpoint: '/organizations/lookups/hardening_template_categories/'
    },
    knowledge_base_category: {
        tagKey: 'category_id',
        lookupKey: 'knowledge_base_category_id',
        endpoint: '/organizations/lookups/knowledge_base_categories/'
    },
    faq_category: {
        tagKey: 'category_id',
        lookupKey: 'faq_category_id',
        endpoint: '/organizations/lookups/faq_categories/'
    },
    environment: {
        tagKey: 'environment_id',
        lookupKey: 'environment_id',
        endpoint: '/organizations/lookups/environments/'
    },
    data_classification: {
        tagKey: 'data_classification_id',
        lookupKey: 'data_classification_id',
        endpoint: '/organizations/lookups/data_classifications/'
    },
    asset_classification: {
        tagKey: 'asset_classification_id',
        lookupKey: 'asset_classification_id',
        endpoint: '/organizations/lookups/asset_classifications/'
    },
    security_zone_classification: {
        tagKey: 'security_zone_classification_id',
        lookupKey: 'security_zone_classification_id',
        endpoint: '/organizations/lookups/security_zone_classifications/'
    },
    work_arrangement: {
        tagKey: 'work_arrangement_id',
        lookupKey: 'work_arrangement_id',
        endpoint: '/organizations/lookups/employee_work_arrangements/'
    }
};

const randomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let index = 0; index < 6; index++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const permalinkKey = (item, permalink_type_id) => {
    let key = null;
    if (permalink_type_id === 1) {
        key = item.id;
    } else if (permalink_type_id === 2) {
        key = item.permalink;
    } else if (permalink_type_id === 3) {
        key = item.token;
    }
    return key;
};

export const createTags = async (type, newTag, options, params) => {
    const { tagKey, lookupKey, endpoint } = TAG_TYPES[type];
    const data = { name: newTag };

    if (tagKey === 'contact_id') {
        const name = newTag.split(' ');
        data.first_name = name[0];
        data.last_name = name.length > 1 ? name.slice(1).join(' ') : '';
    }

    let lookup_type = 'lookups';
    if (requires_color.includes(type)) {
        data.color = randomColor();
        lookup_type = 'lookups_with_color';
    } else if (requires_icon_svg.includes(type)) {
        data.icon = 'other.svg';
        lookup_type = 'lookups_with_icons';
    } else if (requires_icon.includes(type)) {
        data.icon = 'cog';
        lookup_type = 'lookups_with_icons';
    } else if (requires_image.includes(type)) {
        data.image = 'app.png';
        lookup_type = 'lookups_with_images';
    }

    return await addTag(newTag, tagKey, lookupKey, endpoint, data, lookup_type, options, params);
};

const addTag = async (newTag, field, lookupKey, route, data, lookup_type, options, params) => {
    try {

        const response = await UserService.addData(route, data);
        if (response.data.success) {
            let new_value = {
                value: response.data.id ? response.data.id : response.data.data.id,
                name: newTag,
                id: response.data.id ? response.data.id : response.data.data.id
            };

            if (typeof data.color !== undefined && data.color) {
                new_value.color = data.color;
                store.commit('appendLookup', { 'key': lookupKey, 'value': new_value, 'section': 'colors' });
            } else if (typeof data.icon !== undefined && data.icon) {
                new_value.icon = data.icon;
                store.commit('appendLookup', { 'key': lookupKey, 'value': new_value, 'section': 'icons' });
            } else if (typeof data.image !== undefined && data.image) {
                new_value.image = data.image;
                store.commit('appendLookup', { 'key': lookupKey, 'value': new_value, 'section': 'images' });
            } else {
                store.commit('appendLookup', { 'key': lookupKey, 'value': new_value });
            }

            if (!options.value[field]) {
                options.value[field] = [];
            }
            if (Array.isArray(options.value[field])) {
                options.value[field].push(new_value);
            }

            if (typeof params?.value[field] !== undefined && params?.value[field]) {
                params.value[field] = new_value;
            }

            return {
                'success': true,
                'data': new_value,
                'message': `Added ${newTag}`,
                'lookup_type': lookup_type,
                'options': options,
                'params': params
            };
        } else if (response.data.success === null) {
            return {
                'success': null,
                'data': null,
                'message': `Item already existed`,
                'lookup_type': lookup_type,
                'options': options,
                'params': params
            };
        } else {
            const errorMessage = response.data.message || 'Could Not Add Item';
            return {
                'success': false,
                'data': null,
                'message': errorMessage,
                'lookup_type': lookup_type,
                'options': options,
                'params': params
            };
        }


    } catch (error) {
        return {
            'success': false,
            'data': newTag,
            'message': 'Could Not Add Item',
            'lookup_type': lookup_type,
            'options': options,
            'params': params
        };
    }
};


// Clipboard
const { toClipboard } = useClipboard();

export const copyUsernameToClipboard = async (username, html_id) => {
    try {
        document.getElementById(html_id).innerText = username;
        await copyToClipboardFromId(html_id, 'Username');
        showMessage('Copied to Clipboard');
        document.getElementById(html_id).innerText = '';
    } catch (e) {
        showMessage('Failed to copy to Clipboard', 'error');
    }
};

export const copyToClipboard = async (msg, type = null) => {
    await copy(msg, type);
};

export const copyToClipboardFromId = async (id, type = null, should_highlight_text = null) => {

    const msg = document.getElementById(id).innerText;
    // check if has class to remove
    if (document.getElementById(id).classList.contains('d-none')) {
        document.getElementById(id).classList.remove('d-none');
    }

    try {
        await toClipboard(msg, document.getElementById(id));
        if (type) {
            showMessage(`Copied ${type} to Clipboard`);
        } else {
            showMessage('Copied to Clipboard');
        }
    } catch (e) {
        showMessage('Failed to copy to Clipboard', 'error');
    }


    if (should_highlight_text) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(document.getElementById(id));
        selection.removeAllRanges();
        selection.addRange(range);
    } else {
        document.getElementById(id).classList.add('d-none');
    }

};

const copy = async (msg, type = null) => {
    try {
        await toClipboard(msg);
        if (type) {
            showMessage(`Copied ${type} to Clipboard`);
        } else {
            showMessage('Copied to Clipboard');
        }
    } catch (e) {
        showMessage('Failed to copy to Clipboard', 'error');
    }
};
export const getDecryptedField = async (id, field, html_id = null, current_section = 'keys') => {
    try {

        const response = await UserService.getDecryptedField(id, field, current_section);
        if (response?.data?.success && response?.data?.data) {
            const wordSections = field.substr(1).toLowerCase().split('_');
            const secondSection = wordSections[0];
            const thirdSection = wordSections[1] ? ' ' + wordSections[1].charAt(0).toUpperCase() + wordSections[1].slice(1) : '';
            const forthSection = wordSections[2] ? ' ' + wordSections[2].charAt(0).toUpperCase() + wordSections[2].slice(1) : '';

            if (html_id) {
                document.getElementById(html_id).innerText = response.data.data;
                await copyToClipboardFromId(html_id, field.charAt(0).toUpperCase() + secondSection + thirdSection + forthSection);
                document.getElementById(html_id).innerText = '';
            } else {
                await copy(response.data.data, field.charAt(0).toUpperCase() + secondSection + thirdSection + forthSection);
            }

            setTimeout(async () => {
                await toClipboard(' ');
            }, 30000);
        } else {
            const message = response?.data?.message ?? 'Failed to get password';
            showMessage(message, 'error');
        }
    } catch (error) {
        showMessage('Failed to get password', 'error');
    }
};

export const getDecryptedSalary = async (id) => {
    try {
        const response = await UserService.getDecryptedSalary(id, 'salary_encrypted');
        if (response?.data?.success && response?.data?.data) {
            return parseFloat(response.data.data);
        } else {
            const message = response?.data?.message ?? 'Failed to get salary';
            showMessage(message, 'error');
        }
    } catch (error) {
        showMessage('Failed to get salary', 'error');
    }
};

export const formatNumber = (number) => {
    if (number === null || number === undefined) {
        return '';
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const generateReport = (record, include_id = true) => {
    const { report, id, name } = record;
    const params = [
        report.query ? `search_query=${report?.query}` : '',
        report.limit ? `limit=${report?.limit}` : '',
        report.sort ? `sort=${report?.sort}` : '',
        report.ascending ? `ascending=${report?.ascending}` : '',
        report.filters ? `filters=${JSON.stringify(report?.filters)}` : '',
        report.columns ? `columns=${JSON.stringify(report?.columns)}` : ''
    ];
    const params_str = params.filter(param => param).join('&');

    let base = '';
    if (report.root_section) {
        base = `${report.root_section}/${report.sub_id}/${report.parent_section}/${report.id}/${report.section}`;
    } else if (report.parent_section) {
        base = `${report.parent_section}/${report.id}/${report.section}`;
    } else if (report.id) {
        base = `${report.section}/${report.id}`;
    } else {
        base = `${report.section}`;
    }

    if (include_id) {
        return `/${base}?${params_str}&id=${id}&name=${name}`;
    } else {
        return `/${base}?${params_str}`;
    }
};

export const formatCurrency = (value) => {
    if (value === null || value === undefined) {
        value = 0;
    }
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

export const setDefaults = (form, sector = null, copy = false) => {

    let category = { idField: 'category_id', nameField: 'category', extraFields: { icon: 'category_icon' } };
    let environment_color = {
        idField: 'environment_id',
        nameField: 'environment',
        extraFields: { color: 'environment_color' }
    };
    let type = { idField: 'type_id', nameField: 'type' };
    let access_control_type = { idField: 'access_control_type_id', nameField: 'access_control_type' };
    let employee = {
        idField: 'employee_id',
        nameField: () => form.value.employee_name,
        extraFields: { profile_url: 'employee_profile_url', thumbnail: 'employee_thumbnail' }
    };

    if (sector === 'licenses' || sector === 'subscriptions') {
        type = { idField: 'type_id', nameField: 'type', extraFields: { icon: 'type_icon' } };
        category = { idField: 'category_id', nameField: 'category', extraFields: { color: 'category_color' } };
    } else if (sector === 'compensations') {
        employee = {
            idField: 'employee_id',
            nameField: () => form.value.employee_name,
            extraFields: { profile_url: 'employee_profile_url', thumbnail: 'employee_thumbnail' }
        };
    } else if (sector && (sector.includes('service_seats') || sector.includes('subscription_seats') || sector.includes('license_seats') || sector.includes('data_source_shares') || sector.includes('data_source_privileges'))) {
        employee = {
            idField: 'employee_id',
            nameField: () => form.value.employee_name,
            extraFields: { profile_url: 'employee_profile_url', thumbnail: 'employee_thumbnail' }
        };

    } else if (sector === 'documents' || sector === 'configurations' || sector === 'hardening_templates' || sector === 'knowledge_bases' || sector === 'faq' || sector === 'projects' || sector === 'subscriptions' || sector === 'licenses' || sector === 'expenses' || sector === 'services') {
        category = { idField: 'category_id', nameField: 'category', extraFields: { color: 'category_color' } };
    } else if (sector === 'certificates') {
        type = { idField: 'type_id', nameField: 'type', extraFields: { icon: 'type_icon' } };
    } else if (sector === 'physical_storages') {
        type = { idField: 'type_id', nameField: 'type', extraFields: { icon: 'type_icon' } };
        access_control_type = {
            idField: 'access_control_type_id',
            nameField: 'access_control_type',
            extraFields: { icon: 'access_control_type_icon' }
        };
    } else if (sector === 'storages') {
        type = {
            idField: 'type_id',
            nameField: 'type_name',
            extraFields: { image: 'type_image', thumbnail: 'type_thumbnail' }
        };
    }

    const mappings = [
        { idField: 'model_id', nameField: 'model' },
        { idField: 'brand_id', nameField: 'brand' },
        {
            idField: 'contact_id',
            nameField: () => form.value.contact_name,
            extraFields: { profile_url: 'contact_profile_url', thumbnail: 'contact_thumbnail' }
        },
        { idField: 'inherent_risk_id', nameField: 'inherent_risk', extraFields: { color: 'inherent_risk_color' } },
        { idField: 'impact_level_id', nameField: 'impact_level', extraFields: { color: 'impact_level_color' } },
        { idField: 'residual_risk_id', nameField: 'residual_risk', extraFields: { color: 'residual_risk_color' } },
        {
            idField: 'data_classification_id',
            nameField: 'data_classification',
            extraFields: { color: 'data_classification_color' }
        },
        {
            idField: 'priority_id',
            nameField: 'priority',
            extraFields: { color: 'priority_color' }
        },
        {
            idField: 'asset_classification_id',
            nameField: 'asset_classification',
            extraFields: { color: 'asset_classification_color' }
        },
        {
            idField: 'security_zone_classification_id',
            nameField: 'security_zone_classification',
            extraFields: { color: 'security_zone_classification_color' }
        },
        { idField: 'redundancy_type_id', nameField: 'redundancy_type' },
        { idField: 'platform_id', nameField: 'platform' },
        { idField: 'vendor_id', nameField: 'vendor' },
        { idField: 'client_id', nameField: 'client' },
        { idField: 'device_id', nameField: 'device' },
        { idField: 'provider_id', nameField: 'provider' },
        { idField: 'seat_type_id', nameField: 'seat_type', extraFields: { icon: 'seat_type_icon' } },
        { idField: 'subscription_role_id', nameField: 'subscription_role' },
        { idField: 'service_role_id', nameField: 'service_role' },
        { idField: 'license_role_id', nameField: 'license_role' },
        {
            idField: 'application_id',
            nameField: 'application',
            extraFields: { image: 'application_image', thumbnail: 'application_thumbnail' }
        },
        { idField: 'frequency_id', nameField: 'frequency' },
        type,
        access_control_type,
        { idField: 'type_id2', nameField: 'type2' },
        category,
        environment_color,
        { idField: 'sector_id', nameField: 'sector', extraFields: { icon: 'sector_icon' } },
        { idField: 'availability_zone_id', nameField: 'availability_zone' },
        { idField: 'room_id', nameField: 'room' },
        { idField: 'environment_id', nameField: 'environment', extraFields: { color: 'environment_color' } },
        { idField: 'location_id', nameField: 'location' },
        { idField: 'os_architecture_id', nameField: 'os_architecture' },
        { idField: 'folder_id', nameField: 'folder' },
        { idField: 'phone_provider_id', nameField: 'phone_provider' },
        { idField: 'fax_phone_provider_id', nameField: 'fax_phone_provider' },
        { idField: 'status_id', nameField: 'status', extraFields: { color: 'status_color' } },
        { idField: 'status_id2', nameField: 'status2', extraFields: { color: 'status2_color' } },
        { idField: 'os_id', nameField: 'os' },
        { idField: 'priority_id', nameField: 'priority', extraFields: { color: 'priority_color' } },
        employee,
        {
            idField: 'manager_employee_id',
            nameField: () => form.value.manager_employee_name,
            extraFields: { profile_url: 'manager_employee_profile_url', thumbnail: 'manager_employee_thumbnail' }
        },
        {
            idField: 'created_by_employee_id',
            nameField: () => form.value.created_by_employee_name,
            extraFields: { profile_url: 'created_by_employee_profile_url', thumbnail: 'created_by_employee_thumbnail' }
        },
        {
            idField: 'author_user_id',
            nameField: () => form.value.author_user_name,
            extraFields: { profile_url: 'author_user_profile_url', thumbnail: 'author_user_thumbnail' }
        },
        {
            idField: 'assigned_user_id',
            nameField: () => form.value.assigned_user_name,
            extraFields: { profile_url: 'assigned_user_profile_url', thumbnail: 'assigned_user_thumbnail' }
        },
        {
            idField: 'reported_user_id',
            nameField: () => form.value.reported_user_name,
            extraFields: { profile_url: 'reported_user_profile_url', thumbnail: 'reported_user_thumbnail' }
        },
        {
            idField: 'user_id',
            nameField: () => form.value.first_name && form.value.last_name && form.value.first_name.trim().length > 0 && form.value.last_name.trim().length > 0 ? `${form.value.first_name} ${form.value.last_name}` : form.value.display_name,
            extraFields: { profile_url: 'profile_url' }
        },
        {
            idField: 'coauthor_user_id',
            nameField: () => form.value.coauthor_user_name,
            extraFields: { profile_url: 'coauthor_user_profile_url', thumbnail: 'coauthor_user_thumbnail' }
        }
    ];

    mappings.forEach(({ idField, nameField, extraFields = {} }) => {
        if (form.value[idField]) {
            if (typeof form.value[idField] !== 'object') {

                form.value[idField] = {
                    id: form.value[idField],
                    value: form.value[idField],
                    name: typeof nameField === 'function' ? nameField() : form.value[nameField],
                    ...Object.fromEntries(Object.entries(extraFields).map(([key, value]) => [key, form.value[value]]))
                };

                // Verify profile_url is not null if so use profile-13.svg
                if (form.value[idField].profile_url === null) {
                    form.value[idField].profile_url = 'empty.png';
                }

            }
        }
    });


    let options = ref([]);

    for (let i = 1; i <= 61; i++) {
        options.value.push({
            icon: `profile-${i}.svg`,
            name: `Profile ${i}`
        });
    }

    if (form.value.profile_url && form.value.profile_url.length > 0) {
        form.value.profile_url = options.value.find(option => option.icon === form.value.profile_url);
    }

    // if field contains _encrypted then set to null and has_{field} to false
    if (copy) {
        Object.keys(form.value).forEach((key) => {
            if (key.includes('_encrypted')) {
                form.value[key] = null;
                const hasKey = `has_${key}`;
                form.value[hasKey] = false;
            }
        });
    }

};

export const shouldUpdateEncryptedField = (fieldName, decryptedValue, form, props) => {
    if (!fieldName || typeof fieldName !== 'string') {
        throw new Error('Invalid field name');
    }

    // Create the new keys based on the field name
    const hasKey = `has_${fieldName}`;

    // Retrieve corresponding values
    const isReviewing = Boolean(decryptedValue.value[fieldName]);
    const orgHasFormValue = Boolean(props.params[hasKey]);
    const hasFormValue = form.value[fieldName] !== undefined && form.value[fieldName] !== null;

    // Evaluate conditions
    const lostValueDuringReview = orgHasFormValue && !hasFormValue && isReviewing;
    const hasValueDuringReview = hasFormValue && isReviewing;
    const hasNewValueWithoutOriginal = hasFormValue && !orgHasFormValue;

    // Determine if we should update
    return hasValueDuringReview || lostValueDuringReview || hasNewValueWithoutOriginal;
};

export const item_fix = async (current_items, items, field = 'tags') => {
    try {

        if (typeof current_items === 'undefined' || current_items === null) {
            return [];
        }

        if (typeof items === 'undefined' || typeof items.value === 'undefined' || !Array.isArray(items.value)) {

            // if not array tro to make array from string
            if (!Array.isArray(items.value) && items.value.length > 0) {
                item.value = items.value.split(',').map(s => s.trim());
            }

            return [];
        }

        if (current_items.includes(',') || current_items.length > 0) {
            const tags_arr = current_items.split(',').map(s => s.trim());
            let newTags = [];
            let missing_ids = [];

            tags_arr.forEach((s) => {
                const item = items.value.find((x) => x.name === s);
                if (item) {
                    newTags.push({
                        'id': item.value,
                        'name': s,
                        'value': item.value
                    });
                } else {
                    if (!missing_ids.includes(s)) {
                        missing_ids.push(s);
                    }
                }
            });

            if (missing_ids.length > 0) {
                const response = await UserService.postData(`/organizations/get_ids/${field}/`, { 'names': missing_ids });
                if (response.data.success) {
                    response.data.data.forEach((x) => {
                        newTags.push({
                            'id': x.id,
                            'name': x.name,
                            'value': x.id
                        });
                    });
                }
            }
            return newTags;
        }
    } catch (e) {
        return current_items;
    }
};


export const getTemplate = async (template_id, form, loaded, tags, item_type, machine_functions = null, ports = null, ports2 = null, groups = null, security_groups = null, skills = null, experiences = null) => {
    if (template_id === null || template_id.value === null || typeof template_id.value.value === 'undefined') {
        return;
    }
    const id = template_id.value.value;
    form.value = await getFile(item_type, id);

    if (form.value.is_template && form.value.is_template === true) {
        form.value.is_template = false;
    }

    loaded.value = false;
    // we need to resolve the promises from item_fix
    form.value.tags = await item_fix(form.value.tags, tags);

    if (machine_functions) {
        form.value.machine_functions = await item_fix(form.value.machine_functions, machine_functions, 'machine_functions');
    }

    if (ports) {
        form.value.ports = await item_fix(form.value.ports, ports, 'ports');
    }

    if (ports2) {
        form.value.ports2 = await item_fix(form.value.ports2, ports2, 'ports2');
    }

    if (groups) {
        form.value.groups = await item_fix(form.value.groups, groups, 'groups');
    }

    if (security_groups) {
        form.value.security_groups = await item_fix(form.value.security_groups, security_groups, 'security_groups');
    }

    if (skills) {
        form.value.skills = await item_fix(form.value.skills, skills, 'skills');
    }

    if (experiences) {
        form.value.experiences = await item_fix(form.value.experiences, experiences, 'experiences');
    }

    setDefaults(form, item_type, true);
    await nextTick();
    loaded.value = true;
};

export const getUrl = (url_section, id, url_sub_section = null, sub_id = null, sub_sub_section = null, sub_sub_id = null) => {

    if (url_sub_section && sub_id && sub_sub_section && sub_sub_id) {
        return '/' + url_section + '/' + id + '/' + url_sub_section + '/' + sub_id + '/' + sub_sub_section + '/' + sub_sub_id;
    } else if (url_sub_section && sub_id && sub_sub_section) {
        return '/' + url_section + '/' + id + '/' + url_sub_section + '/' + sub_id + '/' + sub_sub_section;
    } else if (url_sub_section && sub_id) {
        return '/' + url_section + '/' + id + '/' + url_sub_section + '/' + sub_id;
    } else if (url_sub_section) {
        return '/' + url_section + '/' + id + '/' + url_sub_section;
    } else {
        return '/' + url_section + '/' + id;
    }
};


export const getName = (item) => {

    if (item.name) {
        return item.name;
    } else if (item.first_name && item.last_name) {
        return item.first_name + ' ' + item.last_name;
    } else if (item.first_name) {
        return item.first_name;
    } else if (item.display_name) {
        return item.display_name;
    } else {
        return 'No Name Provided';
    }
};

export const words_to_replace = {
    'Bitlocker Key Id': 'Bitlocker Key ID',
    'Mfa Provider': 'MFA',
    'Endpoint Protection Platform': 'Endpoint Protection',
    'Has Firewall': 'Firewall',
    'Has Wifi': 'WIFI',
    'Is Wifi Own Vlan': 'WIFI Own VLAN',
    'Has Malware Protection': 'Malware Protection',
    'Has Intrusion Detection': 'Intrusion Detection',
    'Has Intrusion Prevention': 'Intrusion Prevention',
    'Has Dns Filtering': 'DNS Filtering',
    'Has Siem': 'SIEM',
    'Has Physical Lock': 'Physical Lock',
    'Has UPS Connected': 'UPS Connected',
    'Has Dual Power Supply': 'Dual Power Supply',
    'Has Logging Enabled': 'Logging Enabled',
    'Has External Drives': 'External Drives',
    'Has Spf': 'SPF',
    'Has Dkim': 'DKIM',
    'Has Dmarc': 'DMARC',
    'Has Email Spam Protection': 'Email Spam Protection',
    'Has Access Control': 'Access Control',
    'Has Bluetooth': 'Bluetooth',
    'Has Keypad': 'Keypad',
    'Has Contract': 'Contract',
    'Has Security Cameras': 'Security Cameras',
    'Has Card Reader': 'Card Reader',
    'Has Biometric System': 'Biometric System',
    'Has No Seats': 'No Seats',
    'Has Rmm Agent': 'RMM Agent',
    'Has Endpoint Protection': 'Endpoint Protection',
    'Has Cyber Insurance': 'Cyber Insurance',
    'Has Broken Index': 'Broken Index',
    'Has Backup': 'Backup',
    'Has Sso': 'SSO',
    'Has Ups Connected': 'UPS Connected',
    'Has Direct Access To Client Data': 'Direct Access To Client Data',
    'Has Versioning': 'Versioning',
    'Has It Cage': 'IT Cage',
    'Tax Id Encrypted': 'Tax ID',
    'Is Up To Date': 'Up To Date',
    'Is Success': 'Success',
    'Is Domain Restricted': 'Domain Restricted',
    'Is Wheelchair Accessible': 'Wheelchair Accessible',
    'Is Critical': 'Critical',
    'Is Static IP': 'Static IP',
    'Is 2fa Enabled': '2FA Enabled',
    'Is Email Provider': 'Email Provider',
    'Is External Drive Encrypted': 'External Drive Encrypted',
    'Is Mfa Enabled': 'MFA Enabled',
    'Is Favorite': 'Favorite',
    'Is Template': 'Template',
    'Is Archive': 'Archive',
    'Is Online': 'Online',
    'Is Auto Renewal': 'Auto Renewal',
    'Is Breached': 'Breached',
    'Is Pwned': 'Pwned',
    'Is Public': 'Public',
    'Is Private': 'Private',
    'Is In Intelligent Search': 'Intelligent Search',
    'Is Direct Expense': 'Direct Expense',
    'Is Pass Through': 'Pass Through',
    'Is Encrypted': 'Encrypted',
    'Is Server': 'Server',
    'Is Static Ip': 'Static IP',
    'Is Active': 'Active',
    'Is Synced': 'Synced',
    'Is Managed': 'Managed',
    'Is Compliant': 'Compliant',
    'Is Month To Month': 'Month To Month',
    'Is Cloud Storage': 'Cloud Storage',
    'Is Group': 'Group',
    'Is Gateway': 'Gateway',
    'Is Default': 'Default',
    'Ip Address': 'IP Address',
    'Is Client': 'Client',
    'Is Mfa Mandatory': 'MFA Mandatory',
    'Is Confirmed': 'Confirmed',
    'Is Zero Based': 'Zero Based',
    'Is Reverse': 'Reverse',
    'Is Not Employee': 'Not Employee',
    'Is Ssh Key Required': 'Is SSH Key Required',
    'Is Inbound': 'Inbound',
    'Is Fireproof': 'Fireproof',
    'Is Waterproof': 'Waterproof',
    'Is Mdm': 'MDM',
    'Is It Cage Locked': 'IT Cage Locked',
    'Is Owner': 'Owner',
    'Is Locked': 'Locked',
    'Is It Closet': 'IT Closet',
    'Is Conference Room': 'Conference Room',
    'Is External': 'External',
    'Imei': 'IMEI',
    'Esim': 'ESIM',
    'Sim': 'SIM',
    'Spf': 'SPF',
    'Dkim': 'DKIM',
    'Dmarc': 'DMARC',
    'Vpn': 'VPN',
    'Vpn Account': 'VPN Account',
    'Vpn Password': 'VPN Password',
    'Os': 'OS',
    'Ip': 'IP',
    'Pin': 'PIN',
    'Url': 'URL',
    'Lan Ip': 'LAN IP',
    'Os Version': 'OS Version',
    'Sid': 'SID',
    'Ssid': 'SSID',
    'Ram': 'RAM',
    'Dns': 'DNS',
    'Public Ip': 'Public IP',
    'Private Ip': 'Private IP',
    'Cpus': 'CPUs',
    'Sso': 'SSO',
    'Vpn Account Name': 'VPN Account Name',
    'Wan Ip': 'WAN IP',
    'Dns Server 1': 'DNS Server 1',
    'Dns Server 2': 'DNS Server 2',
    'Wan Ip2': 'WAN IP2',
    'Lan Ip2': 'LAN IP2',
    'Cidr': 'CIDR',
    'Mac Address2': 'MAC Address2',
    'Dns Server 3': 'DNS Server 3',
    'Isp': 'ISP',
    'Isp2': 'ISP2',
    'Otp': 'OTP',
    'VPN Local Id': 'VPN Local ID',
    'VPN Remote Id': 'VPN Remote ID',
    'Csr': 'CSR',
    'Api Key': 'API key',
    'Vlan Number': 'VLAN Number',
    'Dhcp Range Start': 'DHCP Range Start',
    'Dhcp Range End': 'DHCP Range End',
    'Dns Server 4': 'DNS Server 4',
    'Mac Address': 'MAC Address',
    'Vpn Remote': 'VPN Remote',
    'Vpn Local': 'VPN Local',
    'Vpn Type': 'VPN Type',
    'Mfa Enabled': 'MFA Enabled',
    'Ssh Key Required': 'SSH Key Required',
    'Created By Employee Id': 'Created By',
    'Author User Id': 'Author',
    'Coauthor User Id': 'Coauthor',
    'Folder Id': 'Folder',
    'DNS Filter Provider Id': 'DNS Filter Provider',
    'Type Id': 'Type',
    'Type Id2': 'Type 2',
    'Source Id': 'Source',
    'Status Id': 'Status',
    'Status Id2': 'Status 2',
    'Security Zone Classification Id': 'Security Zone Classification',
    'Asset Classification Id': 'Asset Classification',
    'Data Classification Id': 'Data Classification',
    'Category Id': 'Category',
    'Fax Phone Provider Id': 'Fax Phone Provider',
    'Room Id': 'Room',
    'OS Architecture Id': 'OS Architecture',
    'Owner Id': 'Owner',
    'Employee Id': 'Employee',
    'Phone Provider Id': 'Phone Provider',
    'Brand Id': 'Brand',
    'Model Id': 'Model',
    'Condition Id': 'Condition',
    'Location Id': 'Location',
    'OS Id': 'OS',
    'Processor Family Id': 'Processor Family',
    'Sector Id': 'Sector',
    'Software Id': 'Software',
    'Device Id': 'Device',
    'App Id': 'App',
    'Hardening Template Id': 'Hardening Template',
    'SIMilarity Threshold': 'Similarity Threshold',
    'API key': 'API Key',
    'Group Id': 'Group ID',
    'Vpc Id': 'VPC ID',
    'Secure Notes Encrypted': 'Secure Notes',
    'Password Encrypted': 'Password',
    'Can Never Expire': 'Can Never Expire',
    'Can Skip Pwned Check': 'Skip Pwned Check',
    'Bitlocker Restore Key Encrypted': 'Bitlocker Restore Key',
    'Encryption Key Encrypted': 'Encryption Key',
    'Passphrase Encrypted': 'Passphrase',
    'PIN Encrypted': 'PIN',
    'Private Key Encrypted': 'Private Key',
    'Secret Encrypted': 'Secret',
    'License Encrypted': 'License'
};


export const columnTitleCase = (str, remove_id = false) => {

    if (remove_id) {
        str = str.replace('_id', '');
        str = str.replace(/_/g, ' ');
    }

    str = str.toLowerCase().replace(/(?:^|\s|-)\w/g, function(match) {
        return match.toUpperCase();
    });

    for (const [key, value] of Object.entries(words_to_replace)) {
        let regex = new RegExp(key, 'g');
        str = str.replace(regex, value);
    }

    return str;

};

export const securityGroupIDValue = async (security_group_name, computedLookups) => {

    security_group_name = security_group_name.trim();
    if (security_group_name === '' || security_group_name === null || security_group_name === ' ') {
        return null;
    }

    let found_id;
    let found = false;
    computedLookups['security_groups'].map((lookup) => {
        if (lookup.name === security_group_name) {
            found_id = lookup.value;
            found = true;
        }
    });

    if (!found) {
        const response = await UserService.postData(`/organizations/get_ids/security_groups/`, { 'names': [security_group_name] });
        if (response.data.success) {
            let newTags = [];
            response.data.data.forEach((x) => {
                newTags.push({
                    'id': x.id,
                    'name': x.name
                });
            });

            newTags.map((lookup) => {
                if (lookup.name === security_group_name) {
                    found_id = lookup.value;
                    found = true;
                }
            });
        }
    }

    return found_id;
};


export const getInitials = (name) => {
    if (!name || !name.split) return '';
    return name.split(' ').map(word => word[0]).join('').toUpperCase().substring(0, 3);
};

export const getBackgroundColor = (initials) => {
    if (!initials) return 'rgba(128, 128, 128, 0.5)'; // Default to gray with 50% opacity
    const firstChar = initials[0].toUpperCase();
    if (['A', 'B', 'C'].includes(firstChar)) return 'rgba(0, 0, 255, 0.5)'; // Blue with 50% opacity
    if (['D', 'E', 'F'].includes(firstChar)) return 'rgba(0, 128, 0, 0.5)'; // Green with 50% opacity
    if (['G', 'H', 'I'].includes(firstChar)) return 'rgba(255, 0, 0, 0.5)'; // Red with 50% opacity
    if (['J', 'K', 'L'].includes(firstChar)) return 'rgba(0, 255, 255, 0.5)'; // Cyan with 50% opacity
    if (['M', 'N', 'O'].includes(firstChar)) return 'rgba(218, 165, 32, 0.5)'; // Goldenrod with 50% opacity
    if (['P', 'Q', 'R'].includes(firstChar)) return 'rgba(128, 128, 128, 0.5)'; // Gray with 50% opacity
    if (['S', 'T', 'U'].includes(firstChar)) return 'rgba(255, 192, 203, 0.5)'; // Pink with 50% opacity
    if (['V', 'W', 'X'].includes(firstChar)) return 'rgba(250, 128, 114, 0.5)'; // Salmon with 50% opacity
    if (['Y', 'Z'].includes(firstChar)) return 'rgba(46, 139, 87, 0.5)'; // Seagreen with 50% opacity

    return 'rgba(128, 128, 128, 0.5)'; // Default gray with 50% opacity
};

export const getTextColor = (initials) => {
    if (!initials) return 'rgb(255, 255, 255)'; // White
    const firstChar = initials[0].toUpperCase();
    if (['A', 'B', 'C'].includes(firstChar)) return 'rgb(173, 216, 230)'; // Lightblue
    if (['D', 'E', 'F'].includes(firstChar)) return 'rgb(144, 238, 144)'; // Lightgreen
    if (['G', 'H', 'I'].includes(firstChar)) return 'rgb(240, 128, 128)'; // Lightcoral
    if (['J', 'K', 'L'].includes(firstChar)) return 'rgb(224, 255, 255)'; // Lightcyan
    if (['M', 'N', 'O'].includes(firstChar)) return 'rgb(250, 250, 210)'; // Lightgoldenrodyellow
    if (['P', 'Q', 'R'].includes(firstChar)) return 'rgb(211, 211, 211)'; // Lightgray
    if (['S', 'T', 'U'].includes(firstChar)) return 'rgb(255, 182, 193)'; // Lightpink
    if (['V', 'W', 'X'].includes(firstChar)) return 'rgb(255, 160, 122)'; // Lightsalmon
    if (['Y', 'Z'].includes(firstChar)) return 'rgb(32, 178, 170)'; // Lightseagreen

    return 'rgb(255, 255, 255)'; // Default white
};

export const initialsStyle = (name, is_large = false) => {
    const initials = getInitials(name);
    const backgroundColor = getBackgroundColor(initials);
    const height = is_large ? '45px' : '30px';
    const width = is_large ? '45px' : '30px';
    return {
        backgroundColor: backgroundColor,
        color: getTextColor(initials),
        border: `1px solid ${backgroundColor.replace('0.5', '1')}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        width: width,
        borderRadius: '50%',
        fontSize: '14px'
    };
};

export const formatUserDate = (date, user) => {
    if (!date) {
        return 'Empty';
    }
    return moment.tz(date, user.timezone).format('MMM DD, YYYY');
};

export const formatUserDateTime = (date, user) => {
    if (!date) {
        return 'Empty';
    }

    return moment.tz(date, user.timezone).format('MMM DD, YYYY hh:mm A');
};

export const formatUserDateTimeFromNow = (date, user) => {
    if (!date) {
        return 'Empty';
    }
    return moment.tz(date, user.timezone).fromNow();
};

export const formatDateTime = (date) => {
    if (!date) {
        return 'Empty';
    }
    return moment(date).format('MMM DD, YYYY hh:mm A');
};

export const formatDate = (date) => {
    if (!date) {
        return 'Empty';
    }
    return moment(date).format('MMM DD, YYYY');
};

export const formatTime = (time) => {
    if (!time) {
        return 'Empty';
    }
    return moment(time, 'HH:mm:ss').format('hh:mm A');
};


export const toTitleCase = (str) => {
    if (!str) {
        return '';
    }
    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

export const randomId = Date.now().toString(36) + Math.random().toString(36).substr(2);
