<template>
    <div class='row p-0 m-1'>
        <div class='col-10'>
            <p class='m-0 p-0 p-header'>
                {{ item.name }}
            </p>

            <div class='m-0 p-0'>
                <div class='row'>
                    <div class='col'>
                        <p class='status-search'>
                            <span class='text-muted'> {{ item.location }} </span>
                            {{ item.employee_name }}
                        </p>
                    </div>
                    <div class='col'>
                        <p class='m-0 p-0 text-muted'>
                            {{item.initiation_date}}
                        </p>
                    </div>
                </div>
            </div>
            
            <div class='mt-0'>
                <p>
                    {{item.termination_date}}
                </p>
            </div>
        </div>
        <div class='col-2'>
            <p class='chevron-right p-0 my-3'>
                <a :href='"/compensations/" + item.id'>
                    <font-awesome-icon icon='chevron-right' size='2x' class='my-3 ml-2' />
                </a>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    /**
     * @typedef {Object} item
     * @property {string}  id
     * @property {string}  name
     * @property {string}  version
     * @property {string}  seats
     * @property {string}  cost
     * @property {string}  category
     * @property {string}  type
     */
    const props = defineProps({
        item: Object,
        is_archive: Boolean
    });

    const item = ref(props.item);

</script>

<style scoped>

    .chevron-right svg {
        top: unset !important;
    }

    .my_icon {
        top: -8px;
        position: relative !important;
    }

    .my_icon b {
        top: 0;
    }


    .p-header {
        font-size: 15px;
    }
</style>
